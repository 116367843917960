export const extractPesel = (pesel) => {
  const s = pesel;
  let poprawnaIloscZnakow = true;
  let poprawneZnaki = true;
  let poprawnaSumaKontrolna = true;
  let poprawnaDataUrodzenia = true;
  let poprawnosc = false;

  // Sprawdź długość, musi być 11 znaków
  if (s.length !== 11) {
      poprawnaIloscZnakow = false;
  }

  // Sprawdź, czy wszystkie znaki to cyfry
  const aInt = [];

  for (let i = 0; i < 11; i += 1) {
      aInt[i] = parseInt(s.substring(i, i + 1), 10);
      if (isNaN(aInt[i])) {
          poprawneZnaki = false;
      }
  }

  // Sprawdź sumę kontrolną
  const wagi = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 1];
  let sum = 0;
  for (let i = 0; i < 11; i += 1) {
      sum += wagi[i] * aInt[i];
  }
  if ((sum % 10) !== 0) {
      poprawnaSumaKontrolna = false;
  }

  // Policz rok z uwzględnieniem XIX, XXI, XXII i XXIII wieku
  let rok = 1900 + (aInt[0] * 10) + aInt[1];
  if (aInt[2] >= 2 && aInt[2] < 8) {
      rok += Math.floor(aInt[2] / 2) * 100;
  }
  if (aInt[2] >= 8) {
      rok -= 100;
  }

  const miesiac = ((aInt[2] % 2) * 10) + aInt[3];
  const dzien = (aInt[4] * 10) + aInt[5];

  function checkDate(d, m, y) {
      const dt = new Date(y, m - 1, d);
      return dt.getDate() === d && dt.getMonth() === m - 1 && dt.getFullYear() === y;
  }

  // Sprawdź poprawność daty urodzenia
  if (!checkDate(dzien, miesiac, rok)) {
      poprawnaDataUrodzenia = false;
  }

  poprawnosc = poprawnaIloscZnakow && poprawneZnaki && poprawnaSumaKontrolna && poprawnaDataUrodzenia;

  let plec = false;
  if (pesel.length >= 10) {
      plec = (aInt[9] % 2 === 1) ? 'M' : 'F';
  }

  const dataUrodzenia = new Date();
  dataUrodzenia.setFullYear(rok, miesiac - 1, dzien);
  dataUrodzenia.setHours(12, 0, 0);

  return {
      valid: poprawnosc,
      sex: plec,
  };
};