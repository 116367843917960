import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core/';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import { extractPesel } from '../../services/peselValidation'
import validator from 'validator';
import './RegisterForm.css'
import "moment/locale/pl";

moment.locale('pl')
class RegisterForm extends Component {

  constructor(props) {
    super(props)
    this.state = this.getInitState(props);
    this.groupLabels = {
      SIX_OLDERS: '6 lat',
      YOUNG_SCHOOL: '7-9 lat',
      OLD_SCHOOL: '10-13 lat',
    }

    this.groupYearRange = {
      SIX_OLDERS: { min: '2016', max: '2017' },
      YOUNG_SCHOOL: { min: '2013', max: '2017' },
      OLD_SCHOOL: { min: '2010', max: '2014' },
    }

    this.dateRange = {
      min: `${this.groupYearRange[props.groupName].min}-01-01`,
      max: `${this.groupYearRange[props.groupName].max}-12-31`,
    };


    this.afterActivitiesOptions = [
      {
        desc: 'Będzie odbierane z Wioski przez uprawnionego opiekuna',
        value: 'Będzie odbierane z Wioski przez uprawnionego opiekuna',
      },
      {
        desc: 'Może samodzielnie wracać do domu, za co biorę odpowiedzialność',
        value: 'Może samodzielnie wracać do domu, za co biorę odpowiedzialność',
      },
    ];
  }

  componentDidMount() {
    this.initFormData(this.props)
  }

  getInitState = (props) => (
    {
      wasSubscribe: false,
      isProcessing: false,
      validation: {
        isValid: true,
      },
      data: {
        pid: '',
        groupName: props.groupName,
        firstname: '',
        lastname: '',
        partnerFirstname: '',
        partnerLastname: '',
        birthday: null,
        afterActivitiesEnd: '',
        zipCode: '',
        city: '',
        street: '',
        number: '',
        phone: '',
        email: '',
        height: '',
        allergies: '',
        personalDataAgreement: false,
        digitalMediaAgreement: false,
        generalRegulationsAgreement: false,
        englishSchoolMarketingAgreement: false,
      }
    }
  )
  validate(model) {
    const validatorResults = {
      pid: extractPesel(model.pid).valid,
      groupName: !!model.groupName,
      firstname: !!model.firstname,
      lastname: !!model.lastname,
      partnerFirstname: true,
      partnerLastname: true,
      birthday: !!model.birthday,
      afterActivitiesEnd: !!model.afterActivitiesEnd,
      zipCode: !!model.zipCode,
      city: !!model.city,
      street: true,
      number: !!model.number,
      phone: validator.isMobilePhone(model.phone),
      email: validator.isEmail(model.email),
      height: !!model.height,
      allergies: true,
      personalDataAgreement: !!model.personalDataAgreement,
      digitalMediaAgreement: !!model.digitalMediaAgreement,
      generalRegulationsAgreement: !!model.generalRegulationsAgreement,
      englishSchoolMarketingAgreement: true,
    } 
    return {
      isValid: Object.values(validatorResults).every(item => item === true),
      results: validatorResults
    }
  }
  

  initFormData(props) {
    this.setState(this.getInitState(props));
  }

  handleChange(fieldName, value) {
    const data = {
      ...this.state.data,
    }
    data[fieldName] = value;
    this.setState({
      ...this.state,
      data,
    });
  }

  submit = () => {
    const model = {...this.state.data};
    model.birthday = model.birthday ? model.birthday.format('DD-MM-YYYY') : false;
    const {isValid, results} = this.validate(model);
    this.setState({
      ...this.state,
      wasSubscribe: false,
    });
    if(isValid) {
      this.setState({
        ...this.state,
        isProcessing: true,
      });
      fetch(
        'https://811tz8z89d.execute-api.eu-central-1.amazonaws.com/prod/participant',
        {
          method: 'post',
          body: JSON.stringify(model),
          headers: {
            'content-type': 'application/json',
          },
        },
      ).then(async(response) => {
        this.setState({
          ...this.state,
          isProcessing: false,
        });
        if(response.status === 200) {
          const jsonData = await response.json();
          if(jsonData.status === 'REGISTERED') {
            this.props.handleSuccess('Rejestracja przebiegła pomyślnie!');
            this.initFormData(this.props);
          } else if(jsonData.status === 'WAITING_LIST') {
            this.props.handleWarning('Uczestnik został umieszczony na liście rezerwowej');
          } else if(jsonData.status === 'REGISTRATION_CLOSED') {
            this.props.handleError('Rejestracja do tej grupy wiekowej została zamknięta!');
          } else if(jsonData.status === 'PID_DUPLICATION') {
            this.props.handleError('Uczestnik o podanym peselu już jest zarejestrowany');
          }
        } else if(response.status === 202) {
          this.props.handleError('Rejestracja do tej grupy wiekowej skończyła się!');
        } else {
          this.props.handleError('Rejestracja nie udała się, spróbuj ponownie za chwilę!');
        }
      }).catch(console.log)
    } else {
      this.setState({
        ...this.state,
        validation: results,
        wasSubscribe: true,
      });
    }
  }
 
  render() {
    const data = this.state.data;
    const validation = this.state.validation;
    const agreementsError = this.state.wasSubscribe
      && (!validation.personalDataAgreement || !validation.digitalMediaAgreement || !validation.generalRegulationsAgreement);
    return (
      <div className="register-form">
        <Button variant="contained" className="fixed-button" color="primary" onClick={this.props.resetGroupHandler}>
          Wróć do wyboru grup
        </Button>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Typography variant="h5" align="left">
              Rejestracja uczestnika do grupy wiekowej {this.groupLabels[data.groupName]}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography variant="body2" align="left">
              * - pole wymagane
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography variant="body2" align="left">
              Dane uczestnika, forma urzędowa, bez zdrobnień
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              required
              error={ this.state.wasSubscribe && !validation.firstname }
              label="Imię"
              value={data.firstname}
              onChange={(event) => {
                this.handleChange('firstname', event.target.value)
              }}
            />  
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              required
              error={ this.state.wasSubscribe && !validation.lastname }
              label="Nazwisko"
              value={data.lastname}
              onChange={(event) => {
                this.handleChange('lastname', event.target.value)
              }}
            />  
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              required
              error={ this.state.wasSubscribe && !validation.pid }
              type="number"
              label="Pesel"
              value={data.pid}
              onChange={(event) => {
                this.handleChange('pid', event.target.value)
              }}
            />  
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <MuiPickersUtilsProvider utils={MomentUtils} locale="pl" libInstance={moment}>
              <DatePicker
                required
                error={ this.state.wasSubscribe && !validation.birthday }
                label="Data urodzenia"
                value={data.birthday}
                onChange={(event) => {
                  this.handleChange('birthday', event)
                }}
                emptyLabel=''
                format="DD-MM-YYYY"
                views={["year", "month", "day", "date"]}
                disableFuture
                openTo="year"
                invalidDateMessage="Niepoprawna data"
                cancelLabel="Anuluj"
                okLabel="OK"
                maxDate={this.dateRange.max}
                minDate={this.dateRange.min}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              required
              error={ this.state.wasSubscribe && !validation.height }
              type="number"
              label="Wzrost (cm)"
              value={data.height}
              onChange={(event) => {
                this.handleChange('height', event.target.value)
              }}
            />  
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle1" align="left">
              Adres
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              label="Ulica"
              value={data.street}
              onChange={(event) => {
                this.handleChange('street', event.target.value)
              }}
            />  
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              required
              error={ this.state.wasSubscribe && !validation.number }
              label="Numer domu"
              value={data.number}
              onChange={(event) => {
                this.handleChange('number', event.target.value)
              }}
            />  
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              required
              error={ this.state.wasSubscribe && !validation.zipCode }
              label="Kod pocztowy"
              value={data.zipCode}
              onChange={(event) => {
                this.handleChange('zipCode', event.target.value)
              }}
            />  
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              required
              error={ this.state.wasSubscribe && !validation.city }
              label="Miasto"
              value={data.city}
              onChange={(event) => {
                this.handleChange('city', event.target.value)
              }}
            />  
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle1" align="left">
              Dane kontaktowe rodzica
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              required
              error={ this.state.wasSubscribe && !validation.phone }
              label="Numer telefonu"
              value={data.phone}
              onChange={(event) => {
                this.handleChange('phone', event.target.value)
              }}
            />  
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              required
              error={ this.state.wasSubscribe && !validation.email }
              label="Email"
              value={data.email}
              onChange={(event) => {
                this.handleChange('email', event.target.value)
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle1" align="left">
              Informacje dodatkowe
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              required
              error={ this.state.wasSubscribe && !validation.afterActivitiesEnd }
              label="Po skończonych zajęciach dziecko:"
              select
              value={data.afterActivitiesEnd}
              onChange={(event) => {
                this.handleChange('afterActivitiesEnd', event.target.value)
              }}
            >
              {
                this.afterActivitiesOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.desc}
                  </MenuItem>
                ))
              }
            </TextField>  
          </Grid>
          <Grid
            item
            xs={12}
          >
            <TextField
              label="Alergie, choroby"
              multiline
              rowsMax="4"
              value={data.allergies}
              onChange={(event) => {
                this.handleChange('allergies', event.target.value)
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle1" align="left">
              Dane osoby (z tej samej kategorii wiekowej), z którą dziecko chce być w plemieniu, forma urzędowa, bez zdrobnień
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              label="Imię"
              value={data.partnerFirstname}
              onChange={(event) => {
                this.handleChange('partnerFirstname', event.target.value)
              }}
            />  
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              label="Nazwisko"
              value={data.partnerLastname}
              onChange={(event) => {
                this.handleChange('partnerLastname', event.target.value)
              }}
            />  
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography variant="subtitle1" align="left" color={agreementsError ? 'error': 'initial'}>
              Zgody *
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.personalDataAgreement}
                  onChange={(event) => {
                    this.handleChange('personalDataAgreement', event.target.checked)
                  }}
                />
              }
              label="Wyrażam zgodę na przetwarzanie danych osobowych mojego dziecka - zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE - w celach związanych z czynnościami rekrutacyjnymi, reklamowymi, organizacyjnymi (w tym upublicznienie imienia i nazwiska dziecka na stronie internetowej KEM w Kielcach i na tablicy informacyjnej w miejscu wydarzenia) oraz w celu realizacji zadań i sprawowania opieki nad dzieckiem podczas Wioski Indiańskiej 2023r., oraz w celu ubezpieczenia dziecka na czas trwania imprezy, co wiąże się z udostępnieniem danych osobowych dziecka, niezbędnych do ubezpieczenia. *"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.digitalMediaAgreement}
                  onChange={(event) => {
                    this.handleChange('digitalMediaAgreement', event.target.checked)
                  }}
                />
              }
              label="Wyrażam zgodę na nieodpłatne prawo do wielokrotnego wykorzystywania zdjęć i materiałów filmowych z wizerunkiem mojego dziecka, w tym wykorzystywanie, utrwalanie, obróbkę i powielanie wykonanych zdjęć za pośrednictwem dowolnego medium, wyłącznie w celu informacji i promocji projektu Wioska Indiańska 2023 *"
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.generalRegulationsAgreement}
                  onChange={(event) => {
                    this.handleChange('generalRegulationsAgreement', event.target.checked)
                  }}
                />
              }
              label={<span>Oświadczam, że zapoznałam /-em się z Regulaminem Wioski Indiańskiej 2023, dostępnym na stronie <a href="http://www.kosciolkielce.pl" target="blank">www.kosciolkielce.pl</a> w zakładce Wioska Indiańska → Rekrutacja oraz z zasadami polityki ochrony danych osobowych. *</span>}
            />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={data.englishSchoolMarketingAgreement}
                  onChange={(event) => {
                    this.handleChange('englishSchoolMarketingAgreement', event.target.checked)
                  }}
                />
              }
              label={<span>Wyrażam zgodę na otrzymywanie materiałów reklamowych organizatorów Wioski Indiańskiej</span>}
            />
          </Grid>
          <Grid
            container
            // xs={12}
            className="button-container"
          >
            <Button variant="contained" disabled={this.state.isProcessing} color="primary" onClick={this.submit}>
              ZAREJESTRUJ
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default RegisterForm;
