import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import RegisterForm from './components/RegisterForm/RegisterForm';
import MySnackbarContentWrapper from './components/MySnackbarContentWrapper/MySnackbarContentWrapper';
// import { xlsxToJson } from './services/xlsx-parser';
// import Store from './services/store';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import bg from './bg.jpg';
import logo from './logo2.svg';
import { Snackbar} from '@material-ui/core';
import scrollToComponent from 'react-scroll-to-component';

import './App.css';
// import { defaultTribeNames } from './config'

const openRegistrationTimestamp = 1685944800000;

class App extends Component {
  constructor() {
    super();
    const urlParams = new URLSearchParams(window.location.search);
    const secret = urlParams.get('secret');
    this.state = {};
    this.state.selectedGroup = null;
    this.state.showError = false;
    this.state.showSuccess = false;
    this.state.showWarning = false;
    this.state.isRegistrationOpen = (Date.now() > openRegistrationTimestamp) || secret === '224a82d9-142c-4d3b-b1b1-86edef46d006';
    this.formButtonsRef = React.createRef();
    console.log('v0.0.4');
    // this.state.tribes = [];
    // this.state.tribesCount = 0;
  }

  // filePicked = (ev) => {
  //   xlsxToJson(ev, this.fileParsed);
  // }

  // fileParsed = (parsedFile) => {
  //   Store.initStore(parsedFile[0]);
  // }

  // handleTribeNameChange = (index) => (ev) => {
  //   this.state.tribes[index] = ev.target.value;
  //   Store.setTribes(this.state.tribes);
  //   this.setState({ tribes: this.state.tribes });
  // }

  // tribesLength = (ev) => {
  //   const tribesCount = ev.target.value;
    
  //   if (tribesCount !== this.state.tribesCount) {
  //     tribesCount > this.state.tribesCount ? this.addTribes(tribesCount - this.state.tribesCount) : this.boundTribesTo(tribesCount)
  //     this.setState({tribesCount: ev.target.value});
  //   }
  // }

  // addTribes(newTribesCount) {
  //   for(let i = 0; i < newTribesCount; i ++){
  //     this.state.tribes.push("");
  //   }

  //   if(defaultTribeNames[this.state.tribes.length]) {
  //     this.state.tribes = defaultTribeNames[this.state.tribes.length];
  //   } 
  //   this.setState({ tribes: this.state.tribes });
  //   Store.setTribes(this.state.tribes);
  // }

  // boundTribesTo(boundTo) {
  //   this.state.tribes.length = boundTo;

  //   if(defaultTribeNames[this.state.tribes.length]) {
  //     this.state.tribes = defaultTribeNames[this.state.tribes.length];
  //   } 
  //   this.setState({ tribes: this.state.tribes });
  //   Store.setTribes(this.state.tribes);
  // }

  // downloadTribeFile = (index) => () => {
  //   Store.saveFile(index);
  // }
  // setParticipantsCount = (ev) => {
  //   Store.setParticipantsCount(ev.target.value);
  // }
  scrollToForm = () => {
    scrollToComponent(this.formButtonsRef.current, { duration: 500, ease:'inCirc'})
  }

  selectedGroup = (selectedGroup) => () => {
    this.setState({
      selectedGroup
    })
  }

  resetGroupHandler = () => {
    this.setState({
      selectedGroup: null,
    })
  }

  handleSuccess = (successMessage) => {
    this.setState({
      selectedGroup: null,
      showSuccess: true,
      successMessage,
    })
  }

  handleWarning = (warningMessage) => {
    this.setState({
      selectedGroup: null,
      showWarning: true,
      warningMessage,
    })
  }

  handleError = (errorMessage) => {
    this.setState({
      showError: true,
      errorMessage,
    })
  }

  handleClose = (snackbarType) => () => {
    if(snackbarType === 'success') {
      this.setState({
        showSuccess: false,
      })
    } else {
      this.setState({
        showError: false,
      })
      this.setState({
        showWarning: false,
      })
    }
  }

  render() {
    const selectedGroup = this.state.selectedGroup;
    const isRegistrationOpen = this.state.isRegistrationOpen;
    return (
      <div className="App">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid
            item
            lg={6}
            sm={4}
            xs={12}
            className="wrapper"
          >
            <div className="img-holder">
              <img className="bg" alt="bg" src={bg} />
              <div className="mask"></div>
              <Grid
                className="goto-form"
                item
                sm={false}
                xs={12}
              >
                <Button color="primary" variant="contained" className="group-button" onClick={this.scrollToForm}>
                  Przejdź do formularza
                </Button>
              </Grid>
            </div>
            <img className="logo" alt="logo" src={logo} />
          </Grid>
          <Grid
            item
            lg={6}
            sm={8}
            xs={12}
          >
            { !isRegistrationOpen  && 
              <Typography variant="subtitle1" align="center">
                Rejestracja zostanie otwarta 5 czerwca 2023 o godzinie 8:00
              </Typography>
            }
            { isRegistrationOpen && selectedGroup == null &&
              <div className="groups-container" ref={this.formButtonsRef}>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Typography variant="h4" className="title">
                    XV Wioska Indiańska Rejestracja
                  </Typography>
                  <Typography variant="subtitle1" align="left">
                    Wybierz rok urodzenia rejestrowanego dziecka:
                  </Typography>
                  <Button color="primary" variant="contained" className="group-button" onClick={this.selectedGroup('SIX_OLDERS')}>
                    2017
                  </Button>
                  <Button color="primary" variant="contained" className="group-button" onClick={this.selectedGroup('YOUNG_SCHOOL')}>
                    2014-2016
                  </Button>
                  <Button color="primary" variant="contained" className="group-button" onClick={this.selectedGroup('OLD_SCHOOL')}>
                    2010-2013
                  </Button>
                </Grid>
              </div>
            }
            { isRegistrationOpen && selectedGroup !== null &&
              <div className="form-container" ref={this.formButtonsRef}>
                <RegisterForm 
                  groupName={selectedGroup}
                  handleSuccess={this.handleSuccess}
                  handleError={this.handleError}
                  handleWarning={this.handleWarning}
                  resetGroupHandler={this.resetGroupHandler}
                />
              </div>
            }
          </Grid>

        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.showSuccess}
          autoHideDuration={60000}
          onClose={this.handleClose('success')}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose('success')}
            variant="success"
            message={this.state.successMessage}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.showWarning}
          autoHideDuration={60000}
          onClose={this.handleClose('warning')}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose('warning')}
            variant="warning"
            message={this.state.warningMessage}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.showError}
          autoHideDuration={60000}
          onClose={this.handleClose('error')}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose('error')}
            variant="error"
            message={this.state.errorMessage}
          />
        </Snackbar>
      </div>
    );
  }
}

export default App;
